.section-manifesto {
  background: $gradient;

  .container {
    max-width: none;
  }

  .logo {
    max-width: 500px;
    min-width: 200px;
    width: 30vw;
    fill: $light;
    margin: 0 0 0 -20px;

    .afa-logo {
      fill: $dark;
    }
  }

  @keyframes intro {
    0% {
      background-size: 0% 30px;
    }
    100% { 
      background-size: 100% 30px;
    }
  }

  .manifesto-content {
    @include font-headline;
    @include fluid-font(35px, 50px);
    line-height: 1;
    color: $dark;
    // text-transform: capitalize;
    padding: 50px 0;

    @include breakpoint(md) {
      font-size: 4vw;
      line-height: 1.1;
    }

    em {
      background-image: linear-gradient(to bottom, transparent 50%, $light 50%);
      background-position: 0 calc(1em - 23px);
      // background-position: 0 0;
      background-size: 0% 25px;
      background-repeat: no-repeat;
      display: inline;
      animation: 3s ease 0s 1 intro;
      animation-delay: calc(var(--animation-order) * 500ms);
      animation-fill-mode: forwards;

      @include breakpoint(lg) {
        background-position: 0 calc(1em - 28px);
        // background-position: 0 0;
        background-size: 0% 30px;
      }
    }

    span:not(.Allies-inline) {
      @include star-bg;
      background-position: center 0.72em;
      color: transparent;
      padding: 0 0.03em;
      margin: 0 0.03em;
    }

    .Allies-inline {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNzUuNSA3NC45IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNzUuNSA3NC45OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01My4xLDcuOGwtNi4zLDY3LjFsLTEwLjctNy42TDM3LjIsNTRsLTE3LjcsMWwtMTAuMSwyMEwwLDY4LjJsNy43LTE1LjFsLTQuNy04LjNsOC45LTAuM0wzMywzLjhsOC4yLDcuOAoJTDQyLjIsMEw1My4xLDcuOHogTTQxLjIsMTJMMjQuOCw0NGwxMy40LTAuNkw0MS4yLDEyeiBNOTkuMiw3MS44bC0zMS4xLDMuMWwtMTAtNi42TDc1LjYsMS4xbDEwLDcuMkw3MS4xLDYzLjdsMjMuMS0yLjJMOTkuMiw3MS44egoJIE0xNDcuMiw3MS44bC0zMS4xLDMuMWwtMTAtNi42bDE3LjQtNjcuM2wxMCw3LjJMMTE5LDYzLjdsMjMuMS0yLjJMMTQ3LjIsNzEuOHogTTE4MS40LDguM0wxNjQsNzQuOWwtMTAtNi42bDE3LjQtNjcuM0wxODEuNCw4LjN6CgkgTTIzMS4xLDEzLjJsLTI0LjgsMS41TDIwMS4yLDM0bDEzLjMtMC44bDUuMSwxMC43bC0yMS4zLDEuNGwtNC44LDE4LjVsMjMuMi0yLjJsNSwxMC40bC0zMS4xLDMuMWwtMTAtNi42bDYuOS0yNi43bC0zLjMtNi42CglsNS4xLTAuM2w1LjQtMjAuNmwtNC45LTkuNkwyMjYsMi40TDIzMS4xLDEzLjJ6IE0yNzUuNSwyNy41bC0xMS4xLTMuOFYxMC40Yy01LDItMTEuOSw4LjEtMTMuOCwxMy45bDIzLjgsMjQuNgoJYy01LDE1LTIwLjEsMjUuMy0zNC43LDI2TDIyOSw2OC4ybDYuMi0yMy4ybDEwLDYuNmwtMywxMS41YzUuNi0wLjMsMTUuNi02LDE4LjEtMTJsLTIzLjktMjQuOGMzLjEtOS43LDExLjMtMjAuOCwyMy40LTI1LjYKCWwxNS42LDcuNVYyNy41eiIvPgo8L3N2Zz4K");      
      background-repeat: no-repeat;
      background-position: center calc(50% + 0.06em);
      color: transparent;
      padding: 0 0.4em;
    }
  }
}
