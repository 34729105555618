$light: white;
$dark: black;
$accent: #00db5f;
$gradient: linear-gradient(90deg, rgba(0,219,95,1) 0%, rgba(0,219,95,1) 25%, rgba(55,236,213,1) 100%);

:root {
  --section-v-offset: 50px;

  @include breakpoint(md) {
    --section-v-offset: 75px;
  }

  @include breakpoint(lg) {
    --section-v-offset: 100px;
  }
}
