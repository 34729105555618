.section-modal {
  background: $dark;
  color: $light;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  opacity: 0;

  &:not(.is-initial) {
    @include transition-fast;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-close {
  @include transition-fast;
  padding: 24px;
  position: fixed;
  top: 0;
  right: 0;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: $accent;
  }
}

.modal-header {
  svg {
    width: 30px;
    margin-bottom: 8px;
  }

  @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
    .em-gradient { 
      display: inline-block;
    }
  }}
}

.modal-container {
  @include breakpoint(lg) {
    display: flex;
  }
}

.modal-sidebar {
  color: $accent;

  @include breakpoint(lg) {
    width: 40%;
    margin-right: 64px;
    max-width: 370px;
  }
}

.modal-content {
  margin-top: var(--section-v-offset);
  flex: 1;

  @include breakpoint(lg) {
    margin-top: 0;
  }
}

.modal-section {
  &:not(:first-child) {
    margin-top: var(--section-v-offset);
  }

  &__cta {
    margin-top: 36px;
  }
}
