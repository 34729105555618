.section-social {
  background: $gradient;
  text-align: center;

  img {
    width: 50%;
    max-width: 400px;
    margin: auto;
    transform: rotate(-20deg);
  }
}

.timer-headline {
  color: $light;
  @include font-headline; 
  @include fluid-font(70px, 150px);
  line-height: 1.1;

  @include breakpoint(md) {
    font-size: 15vw;
  }
}

.social-headline{
  h2{
    color: $dark;
  }
  h3{
    color: $light;
  }
}

.social-ctas {
  margin: 36px 0;

  @include breakpoint(xxl) {
    margin: 48px 0;
  }

  .text-headline {
    color: $light;
  }

  ul {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    @include breakpoint(lg) {
      margin-top: 24px;
    }
  }

  li {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  a {
    @include transition-fast;
    display: block;
    width: 30px;
    height: 30px;

    @include breakpoint(lg) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: $light;
    }
  }
}
