@font-face {
  font-family: founders-grotesk-condensed;
  src: url(../fonts/founders-grotesk-x-condensed-web-bold.woff2) format("woff2"),
       url(../fonts/founders-grotesk-x-condensed-web-bold.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: founders-grotesk;
  src: url(../fonts/founders-grotesk-web-regular.woff2) format("woff2"),
       url(../fonts/founders-grotesk-web-regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: founders-grotesk;
  src: url(../fonts/founders-grotesk-web-bold.woff2) format("woff2"),
       url(../fonts/founders-grotesk-web-bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@mixin font-body {
  font-family: "founders-grotesk", sans-serif;
}

@mixin font-headline {
  font-family: "founders-grotesk-condensed", Impact;
}
