.section-can-do {
  background: $dark;
  color: $light;

  h2 {
    text-align: center;
  }
}

.can-do-blocks {
  display: grid;
  margin-top: calc(var(--section-v-offset) / 1.5);
  grid-gap: 36px;

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.can-do-block {
  position: relative;
  border: 1px solid $light;
  padding: 24px;
  @include transition-fast;

  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoint(xl) {
    padding: 40px 32px;
    min-height: 500px;
  }

  &:before {
    @include transition-fast;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    pointer-events: none;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: $gradient;
    border-width: 0;
  }

  &:hover {
    &:before {
      border-width: 8px;

      @include breakpoint(xl) {
        border-width: 16px;
      }
    }

    svg {
      fill: url(#theme-gradient) #fff;
    }

    .can-do-block__cta, .can-do-highlight {
      background-image: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
    .safari_only { 
      display: inline-block;
    }
  }}

  svg {
    @include transition-fast;
    width: 16px;
    height: 16px;
    margin-bottom: 12px;

    @include breakpoint(lg) {
      width: 24px;
      height: 24px;
    }
  }

  .text-headline {
    margin-bottom: auto;

    @include breakpoint(md-only) {
      font-size: 30px;
    }
  }

  &__cta {
    @include transition-fast;
    text-transform: uppercase;
    margin-top: 100px;
    font-weight: bold;

    @include breakpoint(md) {
      margin-top: 100px;
    }

    @include breakpoint(md-only) {
      font-size: 18px;
    }
  }
}
