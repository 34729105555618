html {
  background-color: black;
  &.js-modal-open {
    overflow: hidden;
  }
}

body {
  @include font-body;
  @include fluid-font(18px, 25px);
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-whatinput="mouse"] {
  *:focus {
    outline: 0;
  }
}
