.section-cant-do {
  background-color: white;
  h2 {
    text-align: center;
  }
}

.cant-do-blocks {
  display: grid;
  margin-top: calc(var(--section-v-offset) / 1.5);
  grid-gap: 36px;

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cant-do-block {
  .text-headline {
    @include breakpoint(md-only) {
      font-size: 30px;
    }
  }
}
