.container {
  width: 88vw;
  max-width: 3200px;
  margin: 0 auto;

  @include breakpoint(lg) {
    width: 82vw;
  }
}

.section-pad {
  padding-top: var(--section-v-offset);
  padding-bottom: var(--section-v-offset);
}

em {
  font-style: normal;
}

.em-gradient {
  background-image: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.em-underline {
  background-image: $gradient;
  background-position: 0 calc(1em - 10px);
  background-repeat: no-repeat;
  background-size: 100% 12px;
  display: inline;
  transition: background-size 0.5s ease-in-out 0.2s;

  &:hover{
    background-size: 0% 12px;
    transition-delay: 0s;
  }
}

.text-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text-headline {
  @include font-headline;
  @include fluid-font(32px, 56px);
  text-transform: uppercase;
  line-height: 0.85;
}

.text-headline-modal {
  @include font-headline;
  @include fluid-font(65px, 135px);
  text-transform: uppercase;
  line-height: 0.85;
}

.text-dim {
  color: #8d8d8d;
  font-size: 0.8em;
}

.richtext {
  p, ul {
    margin: 16px 0;

    @include breakpoint(md) {
      margin: 24px 0;
    }
  }
}

.styled-list {
  li {
    position: relative;
    padding-left: 1.25em;
    padding-bottom: 25px;

    &:before {
      @include star-bg-accent;
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0.25em;

      @include breakpoint(lg) {
        top: 0.3em;
      }

      @include breakpoint(xxl) {
        top: 0.4em;
      }
    }

    a{
      text-decoration: none;
      color: $accent;
      @include transition-fast;

      &:hover{
        text-decoration: underline;
        text-decoration-color: $accent;
        color: white;
      }
    }
  }
}

.star-group {
  img {
    display: block;
    width: 50%;
    max-width: 600px;
    margin: auto;
  }
}

.styled-btn {
  position: relative;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: $gradient;
  border-width: 2px;
  text-transform: uppercase;
  padding: 16px 20px;
  font-weight: bold;
  line-height: 1;
  @include transition-fast;

  @include breakpoint(md) {
    padding: 24px 32px;
  }

  &:before {
    @include transition-fast;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    background-image: $gradient;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover{
    color: $dark;
  }
}
